import { render, staticRenderFns } from "./SelectSuggestGoodsWithPrice.vue?vue&type=template&id=558b344c&scoped=true"
import script from "./SelectSuggestGoodsWithPrice.vue?vue&type=script&lang=js"
export * from "./SelectSuggestGoodsWithPrice.vue?vue&type=script&lang=js"
import style0 from "./SelectSuggestGoodsWithPrice.vue?vue&type=style&index=0&id=558b344c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "558b344c",
  null
  
)

export default component.exports